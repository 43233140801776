import React, {useEffect, useState} from 'react';
import RoundButton from '../../buttons/round-button/round-button';
import RoundInput from '../../inputs/round-input/round-input';
import OtpInput from '../../inputs/otp-input/otp-input';
import Validator, {CompareRule, PatternRule, RequiredRule} from 'devextreme-react/validator';
import {useTranslation} from 'react-i18next';
import {vars} from 'utils/variables';

const {REG_EXP} = vars;

const RecoveryConfirmForm = (props) => {
	const {value, onInputChanged, passwordComparison, sendConfirmRequest, isOtpValid, otpFieldFocusCallback} = props;
	const {t} = useTranslation();

	const [localValid, setLocalValid] = useState(isOtpValid);

	useEffect(() => {
		if (localValid !== isOtpValid) {
			setLocalValid(isOtpValid);
		}
	}, [isOtpValid]);

	return (
		<div className={'password-recovery-component '}>
			<div className={'component-subtitle'}>{t('PASSWORD_RECOVERY_SUBTITLE')}</div>
			<div className={'round-input-wrapper round-otp'}>
				<div className={'round-input-wrapper_input recovery-input'}>
					<OtpInput
						icon={'mdi-onepassword'}
						value={value}
						placeholder={t('PASSWORD_RECOVERY_OTP')}
						onValueChanged={onInputChanged.bind(this, 'otp')}
						withResend={false}
						isValid={localValid}
						validationMessage={t('ERROR_MSG.REQUIRED')}
						focusCallBack={otpFieldFocusCallback}
					/>
				</div>
			</div>
			<RoundInput
				name={'recovery-new-password'}
				icon={'mdi mdi-lock-outline'}
				width={'100%'}
				mode={'password'}
				customClass={'recovery-input'}
				placeholder={t('PASSWORD_RECOVERY_NEW_PASSWORD')}
				onValueChanged={onInputChanged.bind(this, 'password')}
				stylingMode={'underlined'}
			>
				<Validator validationGroup={'recoveryGroup'}>
					<RequiredRule message={'Field is required'}/>
					<PatternRule
						message={t('ERROR_MSG.WRONG_SYMBOLS')}
						pattern={REG_EXP.PASSWORD_PATTERN}
					/>
				</Validator>
			</RoundInput>
			<RoundInput
				name={'recovery-compare-password'}
				icon={'mdi mdi-lock-outline'}
				width={'100%'}
				mode={'password'}
				customClass={'recovery-input'}
				placeholder={t('PASSWORD_RECOVERY_COMPARE_PASSWORD')}
				stylingMode={'underlined'}
			>
				<Validator validationGroup={'recoveryGroup'}>
					<RequiredRule message={'Field is required'}/>
					<CompareRule
						message="New password and confirm password do not match"
						comparisonTarget={passwordComparison}
					/>
				</Validator>
			</RoundInput>
			<RoundButton
				name={'recovery-change-password'}
				text={t('PASSWORD_RECOVERY_CHANGE_PASSWORD')}
				icon={null}
				onClick={sendConfirmRequest}
				customClass={'radial-green size14'}
				width={'100%'}
			/>
		</div>
	);
};

export default RecoveryConfirmForm;
