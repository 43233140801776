import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import {Route, useLocation} from 'react-router-dom';
import {LoginForm} from 'components';
import './unauthorized.scss';

const Unauthorized = (props) => {
	const location = useLocation();

	return (
		<ScrollView height={'auto'} className={'dx-card single-card'}>
			<div>
				<Route render={() => <LoginForm location={location}  {...props}/>}/>
			</div>
		</ScrollView>
	);
}
export default Unauthorized;