import React from 'react';
import RoundButton from "../../buttons/round-button/round-button";
import RoundInput from "../../inputs/round-input/round-input";
import Validator, {RequiredRule} from "devextreme-react/validator";
import {useTranslation} from "react-i18next";

const RecoveryInitForm = (props) => {
	const {value, onEmailChanged, sendRecoveryRequest} = props;
	const {t} = useTranslation();

	return (
		<div className={'password-recovery-component'}>
			<div className={'component-subtitle'}>{t('PASSWORD_RECOVERY_SUBTITLE')}</div>
			<RoundInput
				name={'recovery-email-or-mobile'}
				key={'mdi-at'}
				icon={'mdi mdi-account-circle'}
				width={'100%'}
				text={value}
				customClass={'recovery-input'}
				placeholder={t('PASSWORD_RECOVERY_EMAIL_PLACEHOLDER')}
				onValueChanged={onEmailChanged}
				stylingMode={'underlined'}
			>
				<Validator validationGroup={'recoveryGroup'}>
					<RequiredRule message={'Field is required'}/>
				</Validator>
			</RoundInput>
			<RoundButton
				name={'recovery-recover-password'}
				text={t('PASSWORD_RECOVERY_BUTTON')}
				icon={null}
				onClick={sendRecoveryRequest}
				customClass={'radial-green size14'}
				width={'100%'}
			/>
		</div>
	);
};

export default RecoveryInitForm;
