import React from 'react';

import './ripple-button.scss';

const createRipple = (event) => {
	const button = event.currentTarget;
	const bound = button.getBoundingClientRect();

	const circle = document.createElement("span");
	const diameter = Math.max(button.clientWidth, button.clientHeight);
	const radius = diameter / 2;

	circle.style.width = circle.style.height = `${diameter}px`;
	circle.style.left = `${event.clientX - bound.left - radius}px`;
	circle.style.top = `${event.clientY - bound.top - radius}px`;
	circle.classList.add("ripple");

	const ripple = button.getElementsByClassName("ripple")[0];

	if (ripple) {
		ripple.remove();
	}

	button.appendChild(circle);
};

const RippleButton = ({name, className, handleClick, isLoginPage, children}) => {
	return (
		<button
			name={name}
			className={className}
			onClick={(e) => {
				createRipple(e);
				handleClick(isLoginPage);
			}}
		>
			{children}
		</button>
	);
};

export default RippleButton;
