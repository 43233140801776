import React, {useEffect, useState, useCallback} from 'react';
import {hideOtpPopup, showOtpPopup} from './services/app/appActions';
import {Authorized, Unauthorized} from './layouts';
import OtpPopup from './components/popup/otp-popup';
import {getUserRoutes} from './utils/functions';
import {connect} from 'react-redux';
import {routes} from './app-routes';
import {fetchCurrencies} from 'services/currency/currencyActions';

import 'devextreme/dist/css/dx.common.css';
import './assets/dx.material.custom-scheme.css';
import './App.scss';
import './dx-styles.scss';

const App = ({otpPopupState, hideOtpPopup, initFetchCurrencies, currencies}) => {
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem('user'));
  const enabledRoutes = getUserRoutes(routes);

  const logIn = (userName) => {
    localStorage.setItem('user', userName);
    setLoggedIn(true);
  }

  useEffect(() => {
    if (loggedIn && !currencies) {
      console.log('Fetching currencies');
      initFetchCurrencies();
    }
  }, [loggedIn, currencies, initFetchCurrencies]);

  return (
    <div id={loggedIn ? '' : 'login-page'} className={'app'}>
      {otpPopupState.visible ? (
        <OtpPopup
          {...otpPopupState}
          handleClose={hideOtpPopup}
        />
      ) : null}
      <div className={'powered-by'}></div>
      {loggedIn ? <Authorized menuItems={enabledRoutes}/> : <Unauthorized onLoginClick={logIn}/>}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    otpPopupState: state.app,
    currencies: state.currency.currencies,
  }
};

const mapDispatchToProps = (dispatch) => ({
  showOtpPopup: () => dispatch(showOtpPopup()),
  hideOtpPopup: () => dispatch(hideOtpPopup()),
  initFetchCurrencies: () => dispatch(fetchCurrencies()),
});


export default connect(mapStateToProps, mapDispatchToProps)(App);