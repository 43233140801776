import React, {useState} from 'react';
import Login from './login/login';
import Registration from './registration/registration';
import RippleButton from 'components/buttons/ripple-button/ripple-button';
import {languages, vars} from 'utils/variables';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {registrationRoute} from 'app-routes';
import {parseMultilineText} from 'utils/functions';
import AppPopup from '../popup/app-popup';

const {POPUP_LOGO_FILE_NAME} = vars;

import(process.env.REACT_APP_BRAND === 's3' ? './login-form.scss' : './s-trade-login-form.scss').catch((e) => {
	throw new Error(e);
});

const LoginForm = (props) => {
	const {onLoginClick, location} = props;
	const [isLoginPage, setIsLoginPage] = useState(location.pathname !== registrationRoute);
	const [info, setInfo] = useState({
		InfoText: '',
		SendChannelID: 0,
	});
	const [username, setUsername] = useState('');
	const {i18n, t} = useTranslation();

	const tabClass = 'login-tab';

	const logIn = (username, response) => {
		if (response && response.InfoText) {
			setInfo(response);
			setUsername(username);
		} else {
			onLoginClick(username);
		}
	}

	const popupIconRender = () => {
		return (
			<img width={'60px'} src={`./images/sharpay/${POPUP_LOGO_FILE_NAME[info.SendChannelID]}`} alt={'popup-logo'}/>
		);
	}

	return (
		<>
			<div className={'login-header'}>
				<div>
					<img width={120} src="./images/sharpay/SP_80_80_3.svg" alt=""/>
				</div>
				<div className={'login-switch'}>
					<div className={'login-tabs'}>
						<RippleButton
							name={'login-tab-login'}
							className={classNames(tabClass, {'tab-selected': isLoginPage})}
							handleClick={setIsLoginPage}
							isLoginPage={true}
						>
							{t('COMMON.LOGIN')}
						</RippleButton>
						<RippleButton
							name={'login-tab-registration'}
							className={classNames(tabClass, {'tab-selected': !isLoginPage})}
							handleClick={setIsLoginPage}
							isLoginPage={false}
						>
							{t('COMMON.REGISTRATION')}
						</RippleButton>
					</div>
				</div>
				<div className={'social-networks'}>
					{languages.map((language) => {
						const currentLanguage = i18n.language.split('-')[0];
						const langClass = {
							'language-circle': true,
							'language-selected': currentLanguage === language.name,
						};

						return (
							<div
								className={classNames(langClass)}
								key={language.name}
								onClick={() => {
									if (i18n.language !== language.name) {
										i18n.changeLanguage(language.name).then(() => {
											if (typeof HelpCrunch === 'function') {
												// eslint-disable-next-line no-undef
												HelpCrunch('setPhraseList', language.name);
											}
										}).catch((error) => {
											console.error(error);
										});
									}
								}}
							>
								<img
									className={'language-circle-img'}
									src={language.imgSrc}
									alt=""
								/>
							</div>
						);
					})}
				</div>
				<AppPopup
					icon={popupIconRender()}
					visible={!!(info.InfoText)}
					handleClose={() => {
						if (username) {
							onLoginClick(username);
						} else {
							setInfo({
								InfoText: '',
							});
						}
					}}
					container={'#root'}
				>
					<div className={'info-text'}>{parseMultilineText(info.InfoText).map((line, index) => {
						return (
							<div key={index}>{line}</div>
						);
					})}</div>
				</AppPopup>
			</div>
			{
				isLoginPage ?
					<Login logIn={logIn} setInfo={setInfo}/> :
					<Registration logIn={onLoginClick} params={location.search} setInfo={setInfo}/>
			}
		</>
	);

};

export default LoginForm;
