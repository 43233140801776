import React, {useLayoutEffect, useState,} from 'react';
import {Header} from 'components';
import {Redirect, Route, Switch} from 'react-router-dom';
import useDebounce from 'utils/customHooks/useDebounce';
import RegistrationPlug from 'components/mobile/registration-plug';
import Loader from 'components/loader/loader';
import classNames from "classnames";

import './authorized.scss';

const Authorized = ({menuItems}) => {
	const [Content, setContent] = useState(<Loader isShowLoader={true}/>);

	const updateContent = () => {
		if (window.innerWidth < 720) {
			setContent(<RegistrationPlug/>);
		} else {
			const needIcons = window.innerWidth < 1400;
			setContent(<MainContent needIcons={needIcons} menuItems={menuItems}/>);
		}
	}

	const debouncedUpdate = useDebounce(updateContent, 500);

	useLayoutEffect(() => {
		window.addEventListener('resize', debouncedUpdate);
		debouncedUpdate();
		return () => window.removeEventListener('resize', debouncedUpdate);
	}, []);

	return Content;
}

const MainContent = ({needIcons, menuItems}) => {
	return (
		<div className={classNames('side-nav-outer-toolbar', {'mid-size': needIcons})}>
      <div id={'ctx-wrapper'} onClick={() => {
        const ctxWrapper = document.getElementById('ctx-wrapper');
        const ctxMenu = document.getElementById('ctx-menu');
        ctxWrapper.classList.toggle('visible');
        ctxMenu.classList.toggle('visible');
      }}></div>
			<Header
				needIcons={needIcons}
				menuItems={menuItems}
				className={'layout-header'}
				menuToggleEnabled
				title={'Facilitator Devextreme'}
			/>

			<div id={'main-content'}>
				<div className={'content without-drawer-content'}>
					<Switch>
						{menuItems.map(item => (
							<Route
								exact={item.exact}
								key={item.path}
								path={item.path}
								component={item.component}
							/>
						))}
						<Redirect to={'/main'}/>
					</Switch>
				</div>
			</div>
		</div>
	);
}

export default Authorized;