import React, {useEffect, useState} from 'react';
import PopupSelectBox from '../popup-selectbox';
import {RoundInput} from 'components/inputs/round-input';
import {createNewWallettoCard} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {useTranslation} from 'react-i18next';
import {Button, CheckBox} from 'devextreme-react';
import Validator, {CompareRule, PatternRule, RequiredRule} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import {vars} from 'utils/variables';

import './wallettoAccountFields.scss';

const isActiveButton = (id, agreement) => {
  return id && agreement;
};

const {ADD_ACCOUNT: {PLASTIC_CARD_KIND, VIRTUAL_CARD_KIND}} = vars;

const AddCardFields = (props) => {
  const {t} = useTranslation();
  const newAccountItem = {
    AccountNumber: t('MAIN.ADD_ACCOUNT.NEW_CARD'),
    ID: -1,
  };
  const {
    accountList,
    handleClose,
    userProfile,
    showOtpPopup,
    successHandler,
    paymentSystemMatrix,
  } = props;

  const [wallettoAccountType, setWallettoAccountType] = useState({
    ID: null,
    PaymentSystemSubTypeName: null,
    CurrencyID: null,
    CurrencyName: null,
  });
  //const [localAccountList, setLocalAccountList] = useState(accountList);
  const [localAccountList, setLocalAccountList] = useState([newAccountItem]);
  const [selectedCardKind, setSelectedCardKind] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(newAccountItem);
  const [cvv, setCvv] = useState(null);
  const [agreement, setAgreement] = useState(false);

  useEffect(() => {
    if (paymentSystemMatrix.length === 1) {
      setWallettoAccountType(paymentSystemMatrix[0]);
    }
  }, [paymentSystemMatrix]);

  /*useEffect(() => {
    if (wallettoAccountType.ID) {
      const accountListWithNew = [{
        AccountNumber: t('MAIN.ADD_ACCOUNT.NEW_CARD'),
        ID: -1,
        WallettoParams: {
          WallettoAccountTypeID: wallettoAccountType.PaymentSystemSubTypeID,
        }
      }, ...accountList];

      setLocalAccountList(accountListWithNew.filter((account) => {
        return account.WallettoParams.WallettoAccountTypeID === wallettoAccountType.PaymentSystemSubTypeID;
      }));
    }
  }, [wallettoAccountType]);*/

  const onCardTypeChanged = ({value}) => {
    setWallettoAccountType(paymentSystemMatrix.find((item) => item.ID === value));
  };

  const initWallettoAccount = async ({component}) => {
    const validationGroup = validationEngine.getGroupConfig('walletto');
    let isValid = true;

    if (validationGroup && validationGroup.validators.length > 0) {
      const result = validationEngine.validateGroup('walletto');
      isValid = result && result.isValid;
    }

    if (isValid) {
      component.option('disabled', true);

      const requestData = {
        WallettoCardTypeID: selectedCardKind.ID,
        AccountID: selectedAccount && selectedAccount.ID !== -1 ? selectedAccount.ID : null,
        WallettoAccountTypeID: wallettoAccountType.PaymentSystemSubTypeID,
        PayGateTokenID: wallettoAccountType.PayGateTokenID,
      };

      if (selectedCardKind.CardKindID === VIRTUAL_CARD_KIND) {
        requestData.StaticPassword = cvv;
      }

      try {
        const request = await createNewWallettoCard(requestData);

        if (request.isSuccess) {
          showOtpPopup({
            onResend: async () => {
              createNewWallettoCard(requestData).then((response) => {
                if (response.error) {
                  notifyApp(response.error);
                }
              }).catch((error) => {
                notifyApp(error);
              });
            },
            onSubmit: async (otpCode) => {
              requestData.OtpCode = otpCode;

              try {
                const confirmRequest = await createNewWallettoCard(requestData);

                if (confirmRequest.isSuccess) {
                  successHandler();
                } else {
                  notifyApp(confirmRequest.error);
                }
              } catch (error) {
                notifyApp(error);
              }
            },
            sendChannelId: request.response.SendChannelID,
            infoText: request.response.InfoText,
          });
        } else {
          notifyApp(request.error);
        }
      } catch (error) {
        notifyApp(error);
      } finally {
        setTimeout(() => {
          component.option('disabled', false);
        }, 500);
      }
    }
  };

  const onCvvChanged = ({value}) => {
    setCvv(value);
  };

  const getCardHolder = ({Name, Surname}) => {
    return Name && Surname ? `${Name} ${Surname}` : '';
  };

  const getShippingAddress = ({LivingStreet, LivingCity, LivingCountry, LivingPostalIndex}) => {
    let address = '';
    const shippingData = [LivingStreet, LivingCity, LivingCountry, LivingPostalIndex];

    for (let i = 0; i < 4; i++) {
      if (shippingData[i]) {
        address += `${shippingData[i]}, `;
      }
    }

    return address.replace(/,\s*$/, "");
  };

  const handleAgreement = ({value}) => {
    setAgreement(value);
  };

  const agreementCompare = () => {
    return true;
  };

  const onAccountChanged = ({value}) => {
    setSelectedAccount(localAccountList.find((item) => item.ID === value));
  }

  const onCardKindIdChanged = ({value}) => {
    setSelectedCardKind(wallettoAccountType.WallettoCardTypes.find((item) => item.ID === value));
  }

  return (
    <div className={'walletto-fields'}>
      <div>
        {paymentSystemMatrix.length > 1 ? (
          <PopupSelectBox
            placeholder={t('MAIN.ADD_ACCOUNT.ACCOUNT_SUBTYPE')}
            items={paymentSystemMatrix}
            displayExpr={'PaymentSystemSubTypeName'}
            value={wallettoAccountType.ID}
            changeHandler={onCardTypeChanged}
            enterKeyHandler={initWallettoAccount}
          />
        ) : null}
        <PopupSelectBox
          placeholder={t('MAIN.ADD_ACCOUNT.ACCOUNT')}
          items={localAccountList}
          displayExpr={'AccountNumber'}
          valueExpr={'ID'}
          disabled={true}
          value={selectedAccount ? selectedAccount.ID : null}
          changeHandler={onAccountChanged}
        />
        {wallettoAccountType.ID ? (
          <PopupSelectBox
            placeholder={t('MAIN.ADD_ACCOUNT.CARD_TYPE')}
            items={wallettoAccountType.WallettoCardTypes}
            displayExpr={'Name'}
            valueExpr={'ID'}
            value={selectedCardKind && selectedCardKind.ID}
            changeHandler={onCardKindIdChanged}
          />
        ) : null}
        {selectedCardKind && selectedCardKind.CardKindID === VIRTUAL_CARD_KIND && (
          <RoundInput
            mode={'password'}
            value={cvv}
            figures={true}
            customClass={'round-input'}
            onValueChanged={onCvvChanged}
            placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.3D_SECURE')}
          >
            <Validator validationGroup={'walletto'}>
              <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
              {/*<PatternRule
                pattern={/^[A-Za-z0-9_\.\-,''&()!%?;:#\$*+\/= ]{6,16}$/}
                message={t('ERROR_MSG.WRONG_SYMBOLS')}
              />*/}
            </Validator>
          </RoundInput>
        )}
        {selectedCardKind && selectedCardKind.CardKindID === PLASTIC_CARD_KIND && (
          <div className={'card-holder'}>
            <div className={'card-holder-block'}>
              <div className={'card-holder-label'}>{t('MAIN.ADD_ACCOUNT.NAME')}</div>
              <div className={'card-holder-text'}>
                {getCardHolder(userProfile)}
              </div>
            </div>
            <div className={'card-holder-block'}>
              <div className={'card-holder-label'}>{t('MAIN.ADD_ACCOUNT.SHIPPING_ADDRESS')}</div>
              <div className={'card-holder-text'}>
                {getShippingAddress(userProfile)}
              </div>
            </div>
            <div className={'card-holder-block'}>
              <div className={'card-holder-label'}>{`${t('MAIN.ADD_ACCOUNT.COMMENT')}:`}</div>
              <div className={'card-holder-text'}>
                {t('WALLETTO_PIN_TEXT')}
              </div>
            </div>
          </div>
        )}
        <div className={'dx-field add-walletto-checkbox'}>
          <CheckBox
            name={'registration-agreement-checkbox'}
            id={'agreement-checkbox-text'}
            validationMessageMode={'always'}
            defaultValue={false}
            value={agreement}
            text={''}
            onValueChanged={handleAgreement}
          >
            <Validator validationGroup={'registrationGroup'}>
              <CompareRule message=""
                           comparisonTarget={agreementCompare}/>
            </Validator>
          </CheckBox>
          <div>
            <span>{t('MAIN.ADD_ACCOUNT.CONFIRM_CHECKBOX_PRE')}</span>
            <a
              className={'terms-link'}
              target={'_blank'}
              href={userProfile.McDebitTermsUrl}
            >
              {t('MAIN.ADD_ACCOUNT.CONFIRM_CHECKBOX_LINK')}
            </a>
            <span>{t('MAIN.ADD_ACCOUNT.CONFIRM_CHECKBOX_POST')}</span>
          </div>
        </div>
        <div className={'popup-buttons'}>
          <Button
            id={'popup-buttons_add'}
            width={'50%'}
            height={40}
            text={t('MAIN.ADD_ACCOUNT.ADD')}
            type='default'
            stylingMode='contained'
            disabled={!isActiveButton(wallettoAccountType.ID, agreement)}
            onClick={initWallettoAccount}
          />
          <Button
            id={'popup-buttons_cancel'}
            width={'50%'}
            height={40}
            text={t('MAIN.ADD_ACCOUNT.CANCEL')}
            type='default'
            stylingMode='outlined'
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
}

export default AddCardFields;